/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "../node_modules/common-module/common-module.less";

@blue-dark: #0F1528;
@blue-dark-hover: #518DB380;

@primary-color: #000000;
@layout-body-background: @background-color-base;

@height-base: 40px;
@height-lg: 40px;
@border-radius-base: 4px;

@form-item-label-height: 22px;
@form-vertical-label-padding: 0 0 2px;

@switch-color: #518DB3;

@crm-list-header-padding: 0 @crm-list-base-padding @crm-list-base-padding;
@crm-list-body-bg-color: @layout-body-background;
@crm-detail-body-bg-color: @layout-body-background;
@crm-user-profile-items-bg-dark: @blue-dark;
@crm-user-profile-items-bg-dark-hover: @blue-dark-hover;

.crm-auth-wrapper {
  background-color: #F9F9F9;
}

.crm-auth-wrapper--logo {
  img {
    height: 165px;
  }
}

.crm-layout-sider {
  background-color: @blue-dark;
}

.crm-layout-fragment {
  &-profile {
    color: #ffffff;

    .crm-user-profile {
      &:hover {
        background-color: transparent;
      }

      &-avatar {
        color: #ffffff;
        border: 1px solid #ffffff;
        background-color: #BFBFBF;
        font-size: @font-size-lg;
      }

      &-content {
        &-role {
          color: #ffffff;
          font-size: @font-size-sm;
        }
      }
    }
  }
}

crm-filter-search {
  .ant-input-affix-wrapper {
    .ant-input-suffix {
      order: 1;
      margin-left: 0;
      margin-right: 10px;
      color: #BFBFBF;
    }
    .ant-input {
      order: 2;
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    color: #fff;
  }
}

.crm-menu {
  .ant-menu {
    background-color: @blue-dark;

    .crm-menu__menu-item {
      margin: 0;
      height: 56px;
      line-height: 56px;
      color: #ffffff;

      &:hover {
        background-color: @blue-dark-hover;
        color: #ffffff;
      }

      &.ant-menu-item-selected {
        background-color: @blue-dark-hover;
      }
    }
  }

  .ant-menu-inline .ant-menu-item::after {
    width: 4px;
    background-color: #ffffff;
  }
}

.ant-modal-footer {
  .crm-action-buttons {
    justify-content: flex-end;
  }
}

.ant-input-group-lg .ant-input,
.ant-btn-lg,
.ant-select-lg,
.ant-input-lg {
  font-size: @font-size-base;
  min-height: @height-base;
}

.ant-input-affix-wrapper-lg .ant-input {
  font-size: @font-size-base;
  min-height: auto;
}
